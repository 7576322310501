/* styles for the scrollable div */
.rack-list-container {
    overflow-x: auto;
    height: 700px;
    border: 1px solid #bab8b8;
    border-radius: 4px;
    padding: 8px;
    /* width: 400px; */
  }
  
  /* styles for the rack table */
  .rack-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    line-height: 1.5;
  }
  
  /* styles for the table header */
  .rack-table th {
    background-color: #f7f7f7;
    border: 1px solid #8e8b8b;
    padding: 8px;
    text-align: left;
  }
  
  /* styles for the table body */
  .rack-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  /* styles for each rack row */
  .rack-row:hover {
    background-color: #c3b1b1;
  }
  
  /* styles for the rack name */
  .rack-name {
    font-weight: bold;
  }
  
  /* styles for the pagination */
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
  
  .pagination button {
    background-color: #fff;
    border: 1px solid #ddd;
    color: #555;
    margin-right: 8px;
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .pagination button:hover {
    background-color: #f5f5f5;
  }
  
  .pagination button:focus {
    outline: none;
  }
  
  .btn-edit-r {
    background-color: #c34a6a;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
  }
  
  
  .spiner-postion1 {
   

    
  }



/* ////     */
 .cross-timeline {

background-color: red;

 }

 .near-timeline {


    background-color: yellow;

 }