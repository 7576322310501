.MainDivForInputField {
  display: flex;
  flex-wrap: wrap;
}
.InputRow-left {
  width: 240px;
  /* background: #000; */
  display: flex;
  align-items: flex-end;
  justify-content: left;
  margin-right: 20px;
}
.InputRow-right {
  width: 60%;
  /* background: #000; */
  display: flex;
  align-items: flex-end;
  justify-content: left;
}
.onlymargin {
  padding-left: 20px;
}
.input-container-jj {
  display: flex;
  align-items: flex-end;
  margin-right: 15px;
  margin-bottom: 18px;
}

.dept-btn {
  color: white;
  font-size: 13px;
  padding: 6px 8px;

  border-radius: 5px;
  background: #ed3958;
  color: white;
  border: none;
}






.InputRow-right input,
.InputRow-left input {
  box-sizing: border-box;
  padding: 3.5px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 20px;
  background-color: #f2f2f2;
}


.InputRow-right1 input,
.InputRow-left1 input {

  border: 2px solid #da1e1e;

}





.input-label-b {
  display: inline-block;
  width: 100%;
  text-align: left;
}

.choosfile input {
  padding: 8px;
  font-size: 12px;
  width: 210px;
  /* background: #000; */
}

.select_input {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.upload-btn {
  border-radius: 5px;
  background-color: #ed3958;
  border: none;
  margin-left: 5px;
  font-size: 12px;

  color: white;
}

.choosfile .upload_file {
  background-color: rgb(58, 95, 58);
}

.choosfile ::file-selector-button {
  font-weight: bold;
  color: dodgerblue;
 
  background-color: rgb(75, 74, 74);
}



.InputRow-left .input-validate {


border-color: red

}

.InputRow-right .input-validate {


  border-color: red;
  
  }



  .side_img_list {
    position: fixed;
    top: 50%;
    right: 16rem;
    background-color: #fffcfc;
    width: 150px;
    height: 100px;
    display: flex;
    
border: 1px solid #CCCCCC;
  border-radius: 0.235rem;
  overflow-y: scroll;

    
    
    }

    .side_img_list li {
      list-style: none;
    }