

/* PoppingFont.css */

/* #popping-fonts {
    

 


.showbookStorebydk{
    height: 100%;
    width: 100%;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
  /* this is inside top con.. they have three
   parts = heading ,books show , button */
  .mainshowbookStorebydk{
    height: 100%;
    width: 99.50%;
    border: 1.5px solid rgb(146, 146, 146);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .headingformainshowbookStorebydk{
    height: 10%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }
  .booksmainshowbookStorebydk{
    /* height: 80%; */
    width: 100%;
    overflow: hidden;
  }
  .btnmainshowbookStorebydk{
    height: 10%;
    width: 100%;
  }
.logoheading{
    width: 15%;
    height: 95%;
}

.logoheading img{
    height: 100%;
    width: 90%;
    object-fit: cover;
}
.headingonly{
    width: 68%;
    text-align: center;
}

.headingonly p{

font-size: 23px;
font-weight: 700;
}
.booksmainshowbookStorebydk{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-left: 15px;
}
.bookcart{
    width: 32%;
    height: 150px;
    display: flex;
    align-items: center;
    font-family: 'Poppins';
    justify-content: center;
    /* border: 1px solid rgb(199, 186, 186); */
    margin: 3px;
    overflow: hidden;
    background-color: #6e6b6b0c;
    flex-direction: column;
    /* background: #dbdbdb; */

}








.bookcart_row_1{

    display: flex;
    width: 100%;
    height: 70%;
}


.bookcart_row_2{
    font-family: 'Poppins';

    width: 100%;
    height: 20%;
    color: #185bf7;


 
}

#bk{
display: flex;
    width: 100px;
    height: 70%;
}

#nk{

    background-color: rgb(57, 53, 243);
    width: 80%;
    height: 20px;
    color: #FFFFFF;
    font-size: 8px;
    padding: 4px;




   

}

#sk{

    width: 25px;
    height: 21px;
}

.bookcartimg{
    width: 26%;
    height: 100%;
    border: 1.5px solid rgb(218, 216, 216);
    padding: 0.5px;
}
.bookcartimg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.bookcartcontent{
    width: 49%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 4px;

    
    
}
.bookdetailattribute {
 
    height: 20%;
    font-size: 9px;
    display: flex;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;

   
}
.bookdetailattribute2{
    /* height: 20%; */
    font-size: 8px;
    text-align: justify;

    margin: 0px 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
            line-clamp: 3;
    -webkit-box-orient: vertical;
  

}
#publisher12{
    font-size: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
            line-clamp: 2;
    -webkit-box-orient: vertical;
  

}






.bookdetailattribute3{
 
    height: 20%;
    font-size: 9px;
    display: flex;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;

   
}









#booknametext  {

color: rgb(190, 84, 34);
  
    height: 50%;


}


.bold{
    font-weight: bold;
}
.btnmainshowbookStorebydk{
    margin-top: 20px;
    width: 100%;
    display: flex;
}
.btnmainshowbookStorebydk div{
 width: 45%;
 text-align: right;
}

