.left-side-menu {
  top: 0;
  left: 0;
  width: auto;
  height: 90vh;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  margin-top: 8px;
}

.logo {
  padding: 20px;
  text-align: center;
}

.menu-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-items li {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.menu-items li:not(.active):hover {
  background-color: rgb(138, 134, 135);
}

.menu-items li a {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
}

.menu-items li a img {
  width: 20px;
  margin-right: 10px;
}

.menu-items li a span {
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.active {
  background-color: crimson;
}
