.headers {
  /* background-color: #2c352b; */
  background: linear-gradient(rgb(96, 91, 91), rgb(20, 20, 20));
  height: 50px;
}

.heading-title {
  color: rgb(255, 255, 255);
  margin: 16px;
}
