.product-search-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 960px;
    margin: 20px auto;
    padding: 24px;
    background-color: #f2f2f2;
    float: left;
  }
  
  .search-container {
    position: relative;
    width: 50%;
  }
  
  .search-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: white;
    border: 1px solid gray;
    max-height: 200px;
    overflow-y: auto;
  }
  
  .search-dropdown-item {
    padding: 8px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .search-dropdown-item:hover {
    background-color: rgb(228, 253, 200);
  }
  
  .cart-container {

    width: 50%;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;

/* 
    background-color: aquamarine; */
    width: 50%;
    font-size: 12px;
    border: 2px solid rgb(163, 163, 163);
  }
  
  h2 {
    margin-top: 0;
  }
  
  .otp-verification {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  
  .otp-input {
    margin-right: 10px;
    width: 100px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid gray;
    font-size: 16px;
    text-align: center;
  }
  
  .src_btns {
    font-size: 12px;
    padding: 6px;
    border-radius: 5px;
    background-color: #ed3958;
    border-color: white;
    color: white;
    border: none;
    margin-left: 5px;
}
  
  

  /* /// */

  .book-containers {
    overflow-x: auto;
    height: 100px;
    width: 300px;
    border: 1px solid #bab8b8;
    border-radius: 4px;
    padding: 8px;
    /* width: 400px; */
  }


  .close-btn {

color: red;
cursor: pointer;
font-size: 18px;

  }

  .close-btn:hover {
    background-color: rgb(36, 34, 35);


  }



  .issued {
 color: rgb(221, 30, 30);

  }